import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tour: Dashbot for Voice Skills`}</h1>
    <h2>{`Optimize Response Effectiveness`}</h2>
    <p>{`With Dashbot’s Phrase Clustering, you can identify where the Voice Skill may be breaking down through mishandled or unhandled Intents.`}</p>
    <p>{`The information can be used to train your NLP engine to improve response effectiveness and user satisfaction.`}</p>
    <h3>{`Identify mishandled and unhandled Intents`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/Gn86aDSWD9k?feature=oembed" width="1200" />
    <h3>{`Reduce failure rate`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/e4IGl7d-49Q?feature=oembed" width="1200" />
    <h2>{`NLP Intents `}{`&`}{` Entities`}</h2>
    <p>{`How effective are your NLP and Intents?`}</p>
    <p>{`With voice skills, users can say whatever they want.`}</p>
    <p>{`Our Intent funnels and reports help identify where the Intents may be breaking down or even new use-cases you may not have thought of. You can also see the utterances and messages that mapped to the Intents, as well as all the entities.`}</p>
    <h3>{`Intent Reports`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/02115455/intent-report-1024x613.png",
        "alt": null
      }}></img></p>
    <h3>{`Intent Funnels`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03132512/intent-funnel.png",
        "alt": null
      }}></img></p>
    <h3>{`Intent Mappings`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/02115801/intent-mappings-1024x591.png",
        "alt": null
      }}></img></p>
    <h3>{`Intent Entities`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/02120549/intent-entities-1200-1024x695.png",
        "alt": null
      }}></img></p>
    <h2>{`Device Capabilities`}</h2>
    <p>{`Dashbot provide device capability metrics for voice platforms like Alexa and Google Assistant.`}</p>
    <div id="devicechart" data-highcharts-chart="0" style={{
      "overflow": "hidden"
    }}><div id="highcharts-jzu8hff-0" dir="ltr" className="highcharts-container " style={{
        "position": "relative",
        "overflow": "hidden",
        "width": "759px",
        "height": "400px",
        "textAlign": "left",
        "lineHeight": "normal",
        "zIndex": "0",
        "WebkitTapHighlightColor": "rgba(0, 0, 0, 0)",
        "userSelect": "none"
      }}><svg version="1.1" className="highcharts-root" style={{
          "fontFamily": "\"Lucida Grande\", \"Lucida Sans Unicode\", Arial, Helvetica, sans-serif",
          "fontSize": "12px"
        }} xmlns="http://www.w3.org/2000/svg" width="759" height="400" viewBox="0 0 759 400"><desc>Created with Highcharts 8.2.2</desc><defs><clipPath id="highcharts-jzu8hff-4-"><rect x="0" y="0" width="739" height="338" fill="none"></rect></clipPath></defs><rect fill="#ffffff" className="highcharts-background" x="0" y="0" width="759" height="400" rx="0" ry="0"></rect><rect fill="none" className="highcharts-plot-background" x="10" y="47" width="739" height="338"></rect><rect fill="none" className="highcharts-plot-border" data-z-index="1" x="10" y="47" width="739" height="338"></rect><g className="highcharts-series-group" data-z-index="3"><g className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="0.1" opacity="1" transform="translate(10,47) scale(1 1)" style={{
              "cursor": "pointer"
            }}><path fill="#90ed7d" d="M 255.74380711609285 100.6335196242494 A 140.5 140.5 0 0 1 373.8048483187009 36.00013553095047 L 373.806237298639 37.00013456631737 A 139.5 139.5 0 0 0 256.58548820423454 101.173494573543 Z" className="highcharts-halo highcharts-color-2" data-z-index="-1" fillOpacity="0.25" visibility="hidden"></path><path fill="rgb(124,181,236)" d="M 373.97138391488085 36.000002914164895 A 140.5 140.5 0 1 1 366.9493465406529 316.82297846680774 L 374 176.5 A 0 0 0 1 0 374 176.5 Z" transform="translate(0,0)" stroke="#ffffff" strokeWidth="1" opacity="1" strokeLinejoin="round" className="highcharts-point highcharts-color-0"></path><path fill="rgb(67,67,72)" d="M 366.80902711089976 316.8158576530401 A 140.5 140.5 0 0 1 255.66799977645303 100.75181373066096 L 374 176.5 A 0 0 0 0 0 374 176.5 Z" transform="translate(0,0)" stroke="#ffffff" strokeWidth="1" opacity="1" strokeLinejoin="round" className="highcharts-point highcharts-color-1"></path><path fill="rgb(144,237,125)" d="M 255.74380711609285 100.6335196242494 A 140.5 140.5 0 0 1 373.8048483187009 36.00013553095047 L 374 176.5 A 0 0 0 0 0 374 176.5 Z" transform="translate(0,0)" stroke="#ffffff" strokeWidth="1" opacity="1" strokeLinejoin="round" className="highcharts-point highcharts-color-2"></path></g><g className="highcharts-markers highcharts-series-0 highcharts-pie-series" data-z-index="0.1" opacity="1" transform="translate(10,47) scale(1 1)"></g></g><text x="380" textAnchor="middle" className="highcharts-title" data-z-index="4" style={{
            "color": "#333333",
            "fontSize": "18px",
            "fill": "#333333"
          }} y="24"><tspan>Device Capabilities</tspan></text><text x="380" textAnchor="middle" className="highcharts-subtitle" data-z-index="4" style={{
            "color": "#666666",
            "fill": "#666666"
          }} y="46"></text><text x="10" textAnchor="start" className="highcharts-caption" data-z-index="4" style={{
            "color": "#666666",
            "fill": "#666666"
          }} y="397"></text><g className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="6" opacity="1" transform="translate(10,47) scale(1 1)" style={{
            "cursor": "pointer"
          }}><path fill="none" className="highcharts-data-label-connector highcharts-color-0" stroke="#7cb5ec" strokeWidth="1" d="M 549.4439341833308 180.8721047686853 C 544.4439341833308 180.8721047686853 526.4498531551785 180.41053359075954 520.4518261457945 180.25667653145098 L 514.4537991364105 180.10281947214241" opacity="1"></path><path fill="none" className="highcharts-data-label-connector highcharts-color-1" stroke="#434348" strokeWidth="1" d="M 217.33230674286082 254.39198175716382 C 222.33230674286082 254.39198175716382 238.34414532719222 246.16878133705268 243.68142485530268 243.42771453034896 L 249.01870438341314 240.68664772364525" opacity="1"></path><path fill="none" className="highcharts-data-label-connector highcharts-color-2" stroke="#90ed7d" strokeWidth="1" d="M 287.24444072157024 26.879551774929 C 292.24444072157024 26.879551774929 300.87552615858925 42.67525891892477 303.75255463759555 47.94049463359002 L 306.62958311660185 53.20573034825527" opacity="1"></path><g className="highcharts-label highcharts-data-label highcharts-data-label-color-0" data-z-index="1" transform="translate(554,171)" opacity="1"><text x="5" data-z-index="1" y="16" style={{
                "color": "#000000",
                "cursor": "pointer",
                "fontSize": "11px",
                "fontWeight": "bold",
                "fill": "#000000"
              }}><tspan x="5" y="16" className="highcharts-text-outline" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="2px" strokeLinejoin="round" style={{}}>Users with Audio Only Devices</tspan><tspan x="5" y="16">Users with Audio Only Devices</tspan></text></g><g className="highcharts-label highcharts-data-label highcharts-data-label-color-1" data-z-index="1" transform="translate(0,244)" opacity="1"><text x="5" data-z-index="1" y="16" style={{
                "color": "#000000",
                "cursor": "pointer",
                "fontSize": "11px",
                "fontWeight": "bold",
                "fill": "#000000"
              }}><tspan x="5" y="16" className="highcharts-text-outline" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="2px" strokeLinejoin="round" style={{}}>Users with Display Capable Devices</tspan><tspan x="5" y="16">Users with Display Capable Devices</tspan></text></g><g className="highcharts-label highcharts-data-label highcharts-data-label-color-2" data-z-index="1" transform="translate(112,17)" opacity="1"><text x="5" data-z-index="1" y="16" style={{
                "color": "#000000",
                "cursor": "pointer",
                "fontSize": "11px",
                "fontWeight": "bold",
                "fill": "#000000"
              }}><tspan x="5" y="16" className="highcharts-text-outline" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="2px" strokeLinejoin="round">Users with Both Display and</tspan><tspan dy="14" x="5" className="highcharts-text-outline" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="2px" strokeLinejoin="round">Audio Only Devices</tspan><tspan x="5" y="16">Users with Both Display and</tspan><tspan dy="14" x="5">Audio Only Devices</tspan></text></g></g><g className="highcharts-legend" data-z-index="7"><rect fill="none" className="highcharts-legend-box" rx="0" ry="0" x="0" y="0" width="8" height="8" visibility="hidden"></rect><g data-z-index="1"><g></g></g></g><text x="749" className="highcharts-credits" textAnchor="end" data-z-index="8" style={{
            "cursor": "pointer",
            "color": "#999999",
            "fontSize": "9px",
            "fill": "#999999"
          }} y="395">Highcharts.com</text><g className="highcharts-label highcharts-tooltip highcharts-color-2" style={{
            "whiteSpace": "nowrap",
            "pointerEvents": "none"
          }} data-z-index="8" transform="translate(226,-9999)" opacity="0" visibility="hidden"><path fill="none" className="highcharts-label-box highcharts-tooltip-box highcharts-shadow" d="M 3.5 0.5 L 245.5 0.5 C 248.5 0.5 248.5 0.5 248.5 3.5 L 248.5 40.5 C 248.5 43.5 248.5 43.5 245.5 43.5 L 3.5 43.5 C 0.5 43.5 0.5 43.5 0.5 40.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5" stroke="#000000" strokeOpacity="0.049999999999999996" strokeWidth="5" transform="translate(1, 1)"></path><path fill="none" className="highcharts-label-box highcharts-tooltip-box highcharts-shadow" d="M 3.5 0.5 L 245.5 0.5 C 248.5 0.5 248.5 0.5 248.5 3.5 L 248.5 40.5 C 248.5 43.5 248.5 43.5 245.5 43.5 L 3.5 43.5 C 0.5 43.5 0.5 43.5 0.5 40.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5" stroke="#000000" strokeOpacity="0.09999999999999999" strokeWidth="3" transform="translate(1, 1)"></path><path fill="none" className="highcharts-label-box highcharts-tooltip-box highcharts-shadow" d="M 3.5 0.5 L 245.5 0.5 C 248.5 0.5 248.5 0.5 248.5 3.5 L 248.5 40.5 C 248.5 43.5 248.5 43.5 245.5 43.5 L 3.5 43.5 C 0.5 43.5 0.5 43.5 0.5 40.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5" stroke="#000000" strokeOpacity="0.15" strokeWidth="1" transform="translate(1, 1)"></path><path fill="rgba(247,247,247,0.85)" className="highcharts-label-box highcharts-tooltip-box" d="M 3.5 0.5 L 245.5 0.5 C 248.5 0.5 248.5 0.5 248.5 3.5 L 248.5 40.5 C 248.5 43.5 248.5 43.5 245.5 43.5 L 3.5 43.5 C 0.5 43.5 0.5 43.5 0.5 40.5 L 0.5 3.5 C 0.5 0.5 0.5 0.5 3.5 0.5" stroke="#90ed7d" strokeWidth="1"></path><text x="8" data-z-index="1" y="20" style={{
              "color": "#333333",
              "cursor": "default",
              "fontSize": "12px",
              "fill": "#333333"
            }}><tspan style={{
                "fontSize": "10px"
              }}>Users with Both Display and Audio Only Devices</tspan><tspan x="8" dy="15">312: </tspan><tspan style={{
                "fontWeight": "bold"
              }} dx="0">15.9%</tspan></text></g></svg></div></div>
    <h2>{`Engagement `}{`&`}{` Retention`}</h2>
    <p>{`Understand the stickiness of your skill. Compare new user retention vs existing. View daily, weekly, or monthly retention rates.`}</p>
    <p>{`How often and for how long are your users interacting? View sessions per user, time per session, as well as utterances per session.`}</p>
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>Date</th>
          <th className="text-right" width="15">New Users</th>
          <th className="text-right">1 Day</th>
          <th className="text-right">2 Day</th>
          <th className="text-right">3 Day</th>
          <th className="text-right">4 Day</th>
          <th className="text-right">5 Day</th>
          <th className="text-right">6 Day</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>June 4th 2017</td>
          <td className="text-right">20</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(235, 245, 250)"
          }}>10.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(184, 220, 238)"
          }}>35.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(194, 225, 240)"
          }}>30.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(204, 230, 243)"
          }}>25.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(204, 230, 243)"
          }}>25.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(235, 245, 250)"
          }}>10.00</td>
        </tr>
        <tr>
          <td>June 5th 2017</td>
          <td className="text-right">24</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(179, 217, 236)"
          }}>37.50</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(153, 204, 230)"
          }}>50.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(213, 234, 245)"
          }}>20.83</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(196, 226, 241)"
          }}>29.17</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(221, 238, 247)"
          }}>16.67</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(247, 251, 253)"
          }}>4.17</td>
        </tr>
        <tr>
          <td>June 6th 2017</td>
          <td className="text-right">29</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(213, 234, 245)"
          }}>20.69</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>0.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(227, 241, 248)"
          }}>13.79</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(220, 238, 247)"
          }}>17.24</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>0.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(248, 252, 254)"
          }}>3.45</td>
        </tr>
        <tr>
          <td>June 7th 2017</td>
          <td className="text-right">46</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(189, 222, 239)"
          }}>32.61</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(215, 235, 245)"
          }}>19.57</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(229, 242, 249)"
          }}>13.04</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(247, 251, 253)"
          }}>4.35</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(251, 253, 254)"
          }}>2.17</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(247, 251, 253)"
          }}>4.35</td>
        </tr>
        <tr>
          <td>June 8th 2017</td>
          <td className="text-right">57</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(205, 230, 243)"
          }}>24.56</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(216, 236, 246)"
          }}>19.30</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(252, 254, 255)"
          }}>1.75</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(252, 254, 255)"
          }}>1.75</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(252, 254, 255)"
          }}>1.75</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(241, 248, 252)"
          }}>7.02</td>
        </tr>
        <tr>
          <td>June 9th 2017</td>
          <td className="text-right">67</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(200, 228, 242)"
          }}>26.87</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(249, 252, 254)"
          }}>2.99</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>0.00</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(216, 236, 246)"
          }}>19.40</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(197, 226, 241)"
          }}>28.36</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(219, 237, 246)"
          }}>17.91</td>
        </tr>
        <tr>
          <td>June 10th 2017</td>
          <td className="text-right">53</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(228, 242, 249)"
          }}>13.21</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(252, 254, 255)"
          }}>1.89</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(217, 236, 246)"
          }}>18.87</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(232, 244, 250)"
          }}>11.32</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(236, 246, 251)"
          }}>9.43</td>
          <td className="text-right" style={{
            "backgroundColor": "rgb(217, 236, 246)"
          }}>18.87</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Understand User Behavior`}</h2>
    <p>{`Dashbot has a variety of reports to better understand user behavior and track conversions versus abandonment.`}</p>
    <h3>{`Conversion Goal Tracking`}</h3>
    <p>{`With Dashbot’s Conversion Goal Tracking, you can track any type of goal, including purchases.`}</p>
    <p>{`Quickly see how many users purchase versus abandon, the percentages overtime, as well as the common paths that lead to purchases, and the transcripts in which they occur.`}</p>
    <p>{`Dive deeper into the flows to get a better understanding of user behavior, where the chatbot may be breaking down, and improve the overall user experience.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/vhpqVbuwnCw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h3>{`Goal Funnels`}</h3>
    <p>{`With Dashbot Goal Funnels, you can track how many users make it through the various steps,or stages, of the funnel.`}</p>
    <p>{`This is a quick way to track purchase conversions.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03163150/goal-funnel.png",
        "alt": null
      }}></img></p>
    <h3>{`Conversation Paths`}</h3>
    <p>{`With Dashbot’s Conversation Paths, you can quickly navigate the common flows through your chatbot or voice skill to see drop off and abandonment as well as the paths that lead to conversion.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/04/30163009/conversation-flow.gif",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      